<template>
    <div class="uploadProduct">
        <el-button type="primary" @click="addVariant">添加产品</el-button>
        <el-button type="success" @click="exportToCsv">导出CSV</el-button>
        <el-button type="danger" @click="print">打印</el-button>
        
        <el-table :data="tableData" style="max-width: 100%; text-align: center" header-align="center" header-cell-class-name="header-cell">
            <el-table-column prop="type" label="类型" width="100">
                <template #default="scope">
                    <span :style="{color: scope.row.type==='variable' ? 'green' : 'blue'}">type</span>
                    <el-input v-model="scope.row.type" disabled />
                </template>
            </el-table-column>
            <el-table-column prop="SKU" label="SKU" width="100">
                <template #default="scope">
                    <el-input v-model="scope.row.SKU" :disabled="isDisabled(scope.row)" />
                </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" width="100">
                <template #default="scope">
                    <el-input v-model="scope.row.name" :disabled="isDisabled(scope.row)" />
                </template>
            </el-table-column>
            <el-table-column prop="published" label="已发布" width="1">
                <template #default="scope">
                    <el-input v-model="scope.row.published" disabled />
                </template>
            </el-table-column>
            <el-table-column prop="shortDescription" label="简短描述" width="100">
                <template #default="scope">
                    <el-input v-model="scope.row.shortDescription" :disabled="isDisabled(scope.row)" />
                </template>
            </el-table-column>
            <el-table-column prop="description" label="描述" width="100">
                <template #default="scope">
                    <el-input v-model="scope.row.description" :disabled="isDisabled(scope.row)" />
                </template>
            </el-table-column>
            <el-table-column prop="price" label="常规售价" width="100">
                <template #default="scope">
                    <el-input v-model="scope.row.price" type="number" min="0" />
                </template>
            </el-table-column>
            <el-table-column prop="menu" label="分类" width="100">
                <template #default="scope">
                    <el-select v-model="scope.row.menu" :disabled="isDisabled(scope.row)" placeholder="请选择分类">
                        <el-option v-for="item in menuList" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="images" label="图片" width="400">
                <template #default="scope">
                    <textarea rows="3" cols="50" v-model="scope.row.images" />
                </template>
            </el-table-column>
            <el-table-column prop="parentSKU" label="父级" width="1">
                <template #default="scope">
                    <el-input v-model="scope.row.parentSKU" disabled />
                </template>
            </el-table-column>
            <el-table-column prop="sort" label="位置" width="10">
                <template #default="scope">
                    <el-input v-model="scope.row.sort" :disabled="!isDisabled(scope.row)" />
                </template>
            </el-table-column>
            <el-table-column prop="propertyName" label="属性 1 名称" width="100">
                <template #default="scope">
                    <el-input v-model="scope.row.propertyName" disabled />
                </template>
            </el-table-column>
            <el-table-column prop="propertyValue" label="属性 1 值" width="180">
                <template #default="scope">
                    <el-select v-model="scope.row.propertyValue" :disabled="isDisabled(scope.row)">
                        <el-option v-for="item in sizeList" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="propertyVisible" label="属性 1 可见" width="1">
                <template #default="scope">
                    <el-input v-model="scope.row.propertyVisible" disabled />
                </template>
            </el-table-column>
            <el-table-column prop="propertyGlobal" label="属性 1 的全局" width="1">
                <template #default="scope">
                    <el-input v-model="scope.row.propertyGlobal" disabled />
                </template>
            </el-table-column>
            <el-table-column prop="propertyName" label="属性 2 名称" width="100">
                <template #default="scope">
                    <el-input v-model="scope.row.propertyName2" disabled />
                </template>
            </el-table-column>
            <el-table-column prop="propertyValue" label="属性 2 值" width="180">
                <template #default="scope">
                    <el-select v-model="scope.row.propertyValue2" multiple :disabled="isDisabled(scope.row)">
                        <el-option v-for="item in materialList" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="propertyVisible" label="属性 2 可见" width="1">
                <template #default="scope">
                    <el-input v-model="scope.row.propertyVisible2" disabled />
                </template>
            </el-table-column>
            <el-table-column prop="propertyGlobal" label="属性 2 的全局" width="1">
                <template #default="scope">
                    <el-input v-model="scope.row.propertyGlobal2" disabled />
                </template>
            </el-table-column>
            <el-table-column label="操作" width="600">
                <template #default="scope">
                    <el-button v-if="scope.row.type === 'variable'" type="primary" @click="addVariation(scope.row)">添加</el-button>
                    <el-button v-if="scope.row.type === 'variable'" type="danger" @click="deleteRow(scope.row)">删除</el-button>
                    <el-button v-if="scope.row.type === 'variation'" type="danger" @click="deleteVariation(scope.row)">删除变量</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
  
<script setup>
  import { reactive } from 'vue'
  import Papa from 'papaparse'
  import { ElMessage, ElMessageBox } from 'element-plus'


  const menuList = reactive([
    {
        label: 'Metal',
        value: 'Metal'
    },
    {
        label: 'Wood',
        value: 'Wood'
    },
    {
        label: 'Water',
        value: 'Water'
    },
    {
        label: 'Fire',
        value: 'Fire'
    },
    {
        label: 'Earth',
        value: 'Earth'
    },
    {
        label: 'Ornaments',
        value: 'Ornaments'
    },
    {
        label: 'Multi-Element',
        value: 'Multi-Element'
    }
  ])

  const materialList = reactive([
    {label: 'Aquamarine', value: 'Aquamarine'},
    {label: 'Red Stone', value: 'Red Stone'},
    {label: 'Rose Quartz', value: 'Rose Quartz'},
    {label: 'Red Agate', value: 'Red Agate'},
    {label: 'Coloured Crystals', value: 'Coloured Crystals'},
    {label: 'Amethyst', value: 'Amethyst'},
    {label: 'Citrine', value: 'Citrine'},
    {label: 'Green Quartz', value: 'Green Quartz'},
    {label: 'Opal', value: 'Opal'},
    {label: 'Aventurine', value: 'Aventurine'},
    {label: 'Olivine', value: 'Olivine'},
    {label: 'Obsidian', value: 'Obsidian'},
    {label: 'Labradorite', value: 'Labradorite'},
    {label: 'Lapis Lazuli', value: 'Lapis Lazuli'},
    {label: 'Rhodochrosite', value: 'Rhodochrosite'},
    {label: 'Turquoise', value: 'Turquoise'},
    {label: 'Amazonite', value: 'Amazonite'},
    {label: 'Colorful Fluorite', value: 'Colorful Fluorite'},
    {label: 'Red Chalcedony Agate', value: 'Red Chalcedony Agate'},
    {label: 'Tourmaline', value: 'Tourmaline'},
    {label: 'Green Aventurine', value: 'Green Aventurine'},
    {label: 'White Quartz', value: 'White Quartz'},
    {label: 'Jasperite', value: 'Jasperite'},
    {label: 'Strawberry Quartz', value: 'Strawberry Quartz'},
    {label: 'Three-Color', value: 'Three-Color'},
    {label: 'White Jade+Obsidian', value: 'White Jade+Obsidian'},
    {label: 'Yellow Amethyst', value: 'Yellow Amethyst'},
    {label: 'Strawberry Crystal', value: 'Strawberry Crystal'},
    {label: 'Red Rutilated Quartz', value: 'Red Rutilated Quartz'},
    {label: 'Amethyst+Citrine', value: 'Amethyst+Citrine'},
    {label: 'Amethyst+White Crystal', value: 'Amethyst+White Crystal'},
    {label: 'Amethyst+Aquamarine', value: 'Amethyst+Aquamarine'},
    {label: 'Amethyst+Rose Quartz', value: 'Amethyst+Rose Quartz'},
    {label: 'Rose Quartz+Aquamarine', value: 'Rose Quartz+Aquamarine'},
    {label: 'Colorful Fluorite+Rose Quartz', value: 'Colorful Fluorite+Rose Quartz'},
    {label: 'Green Aventurine+Aquamarine', value: 'Green Aventurine+Aquamarine'},
    {label: 'Rose Quartz+Amethyst+Aquamarine', value: 'Rose Quartz+Amethyst+Aquamarine'},
    {label: 'Fluorite', value: 'Fluorite'},
    {label: 'Amethyst+Yellow Amethyst', value: 'Amethyst+Yellow Amethyst'},
  ])
  const sizeList = reactive([
    {label: 'H11*W4.5cm', value: 'H11*W4.5cm'},
    {label: 'H18cm', value: 'H18cm'},
    {label: 'H22*W13cm', value: 'H22*W13cm'},
    {label: 'H10*W6cm', value: 'H10*W6cm'},
    {label: 'H15*W6cm', value: 'H15*W6cm'},
    {label: 'H24*W14cm', value: 'H24*W14cm'},
    {label: '18*5.8*4cm', value: '18*5.8*4cm'},
    {label: 'L7*W6*H13cm', value: 'L7*W6*H13cm'},
    {label: '15*10*5cm', value: '15*10*5cm'},
    {label: 'H14cm', value: 'H14cm'},
    {label: 'H18*W12cm', value: 'H18*W12cm'},
    {label: 'H10.5*W5.5cm', value: 'H10.5*W5.5cm'},
    {label: 'H12cm', value: 'H12cm'},
    {label: 'H11*W7cm', value: 'H11*W7cm'},
    {label: 'H23*W15cm', value: 'H23*W15cm'},
    {label: 'H17*W10cm', value: 'H17*W10cm'},
    {label: 'H24*W21cm', value: 'H24*W21cm'},
    {label: 'H18*W14cm', value: 'H18*W14cm'},
    {label: '23*14*8cm', value: '23*14*8cm'},
    {label: 'H20*W20cm', value: 'H20*W20cm'},
    {label: 'H25cm', value: 'H25cm'},
    {label: 'H30cm', value: 'H30cm'},
    {label: 'H13*W14cm', value: 'H13*W14cm'},
    {label: 'H18*W15cm', value: 'H18*W15cm'},
    {label: 'H14*W8cm', value: 'H14*W8cm'},
    {label: 'H12*W8cm', value: 'H12*W8cm'},
    {label: 'H11cm', value: 'H11cm'},
    {label: 'H14*W10cm', value: 'H14*W10cm'},
    {label: 'H11*W8cm', value: 'H11*W8cm'},
    {label: 'H10*W9cm', value: 'H10*W9cm'},
    {label: 'See picture', value: 'See picture'},
    {label: 'H15*W10cm', value: 'H15*W10cm'},
    {label: 'H13*W15cm', value: 'H13*W15cm'},
    {label: 'H10*W10cm', value: 'H10*W10cm'},
    {label: 'H15*W11cm', value: 'H15*W11cm'},
    {label: 'H16*W14cm', value: 'H16*W14cm'},
  ])

  const print = () => {
    console.log(tableData)
  }
  const isDisabled = (scope)=>{
    if(scope.type === 'variable'){
        return false
    }
    return true
  }

  const deleteRow = (row) => {
    const sku = row.SKU
    ElMessageBox.confirm('删除产品，对应变量也会删除，确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    }).then(() => {
        tableData.forEach(item => {
            if(item.parentSKU === sku && item.type === 'variation'){
                tableData.splice(tableData.indexOf(item), 1)
            }
        })
        tableData.splice(tableData.indexOf(row), 1)
    }).catch(() => {
        ElMessage.info('已取消删除')
    })
  }

  const deleteVariation = (row) => {
    tableData.splice(tableData.indexOf(row), 1)
  }

  const tableData = reactive([])

  const addVariant = () =>{
    tableData.push({
        type: 'variable',
        SKU: '',
        name: '',
        published: '1',
        shortDescription: '',
        description: '', 
        price: 0,
        menu: 'Ornaments',
        images: '',
        parentSKU: '',
        sort: 0,
        propertyName: 'size',
        propertyValue: '',
        propertyVisible: '1',
        propertyGlobal: '1',
        propertyName2: 'material', 
        propertyValue2: [],
        propertyVisible2: '1',
        propertyGlobal2: '1',
    })
  }
  const addVariation = (data) => {
    if(!data.SKU) {
      ElMessage.error('请先填写父级SKU')
      return
    }
    if(!data.propertyValue || data.propertyValue.length === 0) {
      ElMessage.error('请先填写属性值')
      return
    }
    const index = tableData.indexOf(data)
    const variation = [...data.propertyValue2]
    console.log(variation);
    
    variation.forEach((item, i) => {
        tableData.splice(index + i + 1, 0, {
            type: 'variation',
            SKU: '',
            name: data.name,
            published: '1',
            shortDescription: '',
            description: '',
            price: data.price,
            menu: '',
            images: '',
            parentSKU: data.SKU,
            sort: i+1,
            propertyName: 'size',
            propertyValue: data.propertyValue,
            propertyVisible: '1',
            propertyGlobal: '1',
            propertyName2: 'material',
            propertyValue2: [item],
            propertyVisible2: '1',
            propertyGlobal2: '1',
        })
    })
  }
  
  // 导出CSV
  const exportToCsv = () => {
    const headers = [
      '类型',
      'SKU',
      '名称',
      '已发布',
      '简短描述',
      '描述',
      '常规售价',
      '分类',
      '图片',
      '父级',
      '位置',
      '属性 1 名称',
      '属性 1 值',
      '属性 1 可见',
      '属性 1 的全局',
      '属性 2 名称',
      '属性 2 值', 
      '属性 2 可见',
      '属性 2 的全局',
      'Swatches'
    ]
  
    const csvData = tableData.map(item => {
      let swatches = ''

      return [
        item.type,
        item.SKU,
        item.name,
        item.published,
        item.shortDescription,
        item.description,
        item.price,
        item.menu,
        item.images,
        item.parentSKU,
        item.sort,
        item.propertyName,
        item.propertyValue,
        item.propertyVisible,
        item.propertyGlobal,
        item.propertyName2,
        item.propertyValue2.join(','),
        item.propertyVisible2, 
        item.propertyGlobal2,
        swatches
      ]
    })
  
    const dataWithHeaders = [headers, ...csvData]
  
    // 将 el-table 数据转换为 CSV 格式
    const csv = Papa.unparse(dataWithHeaders);
  
    // 创建一个链接元素，用于触发下载
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
  
    // 创建文件下载链接
    link.href = URL.createObjectURL(blob);
    link.download = '产品.csv';
    link.click();
  }
  </script>
  
  <style>
  .header-cell {
    text-align: center;
    font-weight: bold;
    color: #333;
  }
  .header-cell .cell {
    text-align: center;
  }
  </style>